import { PARENT_URL } from '@apps/config';
import usePostMsgContext from '@apps/containers/WebviewProvider/hooks';
import { priceFormat } from '@apps/utils/format-price';
import Box, { BoxProps } from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography, { TypographyProps } from '@mui/material/Typography';
import createBasePath from '@stack/helpers/lib/createBasePath';
import { useGetResponsive } from '@stack/helpers/lib/responsive';
import styled from 'styled-components';

import StyledImage from '../Image';

export type CarCardProps = {
  slug: string;
  name: string;
  image: string;
  monthlyPayment: number;
  priceRange: [number, number];
  brand?: string;
  containerProps?: BoxProps;
  isNewCar?: boolean;
  webView?: boolean;
  isContentAlign?: boolean;
  imageWrapperProps?: {
    width: string;
    height: string;
  };
  nameProps?: TypographyProps;
};

export const ImageWrapper = styled.div<{
  minWidth?: string;
  width?: string;
  height?: string;
}>`
  min-width: ${(props) => props.minWidth ?? (props.width || '155px')};
  width: ${(props) => props.width || '155px'};
  height: ${(props) => props.height || '92px'};
  transition: transform 0.3s;
  draggable: false;
  padding-top: 4px;

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: 768px) {
    min-width: ${(props) => props.minWidth ?? (props.width || '145px')};
    width: ${(props) => props.width || '145px'};
    height: ${(props) => props.height || '82px'};
  }
`;

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const CarCard: React.FC<CarCardProps> = (props) => {
  // ======================= HOOKS
  const { clickEvent } = usePostMsgContext();
  const { isWeb } = useGetResponsive();

  const {
    slug,
    name,
    image,
    monthlyPayment,
    priceRange,
    brand,
    containerProps,
    webView,
    isNewCar = false,
    imageWrapperProps = {},
    isContentAlign = false,
    nameProps,
  } = props;

  // ======================= VARIABLES
  const { basePath } = createBasePath({ webView, type: 'cars' });

  const newCarHref = `${basePath}/${brand}/${slug}`;
  const onClick =
    !isNewCar || !webView
      ? undefined
      : clickEvent(newCarHref, false, {
          title: name,
          params: {
            brand,
            slug,
          },
        });

  // ======================= VIEWS
  const renderPrice = () => {
    const isFixedPrice = priceRange[0] === priceRange[1];
    const isTBC = priceRange[0] === 0 && priceRange[1] === 0;
    const priceInfo = isFixedPrice
      ? priceFormat({ price: priceRange?.[0], prefix: 'RM ' })
      : `${priceFormat({
          price: priceRange?.[0],
          prefix: 'RM ',
        })} - ${priceFormat({
          price: priceRange?.[1],
          prefix: 'RM ',
        })}`;
    const monthlyPaymentInfo = priceFormat({
      price: monthlyPayment,
      prefix: 'RM ',
      postfix: ' | Month',
    });

    if (isTBC)
      return (
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 700,
            textAlign: {
              xs: !isContentAlign ? 'flex-start' : 'center',
              md: 'center',
            },
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: '#31ACBF',
          }}
        >
          {'TBC'}
        </Typography>
      );
    if (isWeb || isContentAlign) {
      return (
        <>
          <Stack
            direction="row"
            sx={{
              maxWidth: '100%',
              gap: 1,
              flexWrap: 'wrap',
              justifyContent: {
                xs: !isContentAlign ? 'flex-start' : 'center',
                md: 'center',
              },
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 700,
                textAlign: {
                  xs: !isContentAlign ? 'flex-start' : 'center',
                  md: 'center',
                },
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                width: '100%',
                overflow: 'hidden',
                color: '#31ACBF',
              }}
            >
              {priceInfo}
            </Typography>
          </Stack>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              textAlign: {
                xs: !isContentAlign ? 'flex-start' : 'center',
                md: 'center',
              },
              color: 'text.primary',
              paddingTop: !isContentAlign ? 0 : '0px',
            }}
          >
            {monthlyPaymentInfo}
          </Typography>
        </>
      );
    }
    return (
      <>
        <Stack
          direction="row"
          sx={{
            maxWidth: '100%',
            gap: 1,
            flexWrap: 'wrap',
            justifyContent: {
              xs: !isContentAlign ? 'flex-start' : 'center',
              md: 'center',
            },
          }}
        >
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              textAlign: {
                xs: !isContentAlign ? 'flex-start' : 'center',
                md: 'center',
              },
              whiteSpace: 'nowrap',
              // WebkitBoxOrient: 'vertical',
              // WebkitLineClamp: 2,
              // textOverflow: 'ellipsis',
              // width: '100%',
              // overflow: 'hidden',
              color: '#31ACBF',
            }}
          >{`${priceFormat({
            price: priceRange?.[0],
            prefix: 'RM ',
          })} -`}</Typography>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 700,
              textAlign: {
                xs: !isContentAlign ? 'flex-start' : 'center',
                md: 'center',
              },
              whiteSpace: 'nowrap',
              // width: '100%',
              color: '#31ACBF',
            }}
          >
            {priceFormat({
              price: priceRange?.[1],
              prefix: 'RM ',
            })}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: 14,
            fontWeight: 700,
            textAlign: {
              xs: !isContentAlign ? 'flex-start' : 'center',
              md: 'center',
            },
            color: 'text.primary',
            paddingTop: !isContentAlign ? 0 : '0px',
          }}
        >
          {monthlyPaymentInfo}
        </Typography>
      </>
    );
  };
  return (
    <a
      href={
        isNewCar
          ? newCarHref
          : `${PARENT_URL}/new-cars/${brand}/${brand}-${slug}`
      }
      onClick={onClick}
      target={isNewCar ? undefined : '_blank'}
      style={{
        textDecoration: 'none',
        color: 'inherit',
      }}
      draggable={false}
    >
      <Box
        sx={{
          minWidth: isNewCar ? undefined : 200,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: 5,
          ...containerProps?.sx,
        }}
      >
        <ImageWrapper {...imageWrapperProps}>
          <StyledImage
            loading={'lazy'}
            fetchPriority={'auto'}
            priority={false}
            src={image}
            alt={name}
            width={175}
            height={92}
          />
        </ImageWrapper>
        <Box
          className="url-ani-2"
          sx={{ maxWidth: '100%', overflow: 'hidden' }}
        >
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 700,
              textAlign: {
                xs: !isContentAlign ? 'flex-start' : 'center',
                md: 'center',
              },
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              marginBottom: !isContentAlign ? 0 : '0px',
              color: 'text.primary',
              textOverflow: 'ellipsis',
              width: '100%',
              ...nameProps?.sx,
            }}
          >
            {name}
          </Typography>
          {renderPrice()}
        </Box>
      </Box>
    </a>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default CarCard;
