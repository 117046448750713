import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Dialog } from '@apps/components/Dialog';
import StyledImage from '@apps/components/Image';
import getAds, { AdsBlockType } from '@apps/config/API/ads.config';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import Link from 'next/link';

import AdsPopupProps from './props';

const AdsPopup: React.FC<AdsPopupProps> = ({
  containerStyle,
  imageStyle,
  checkAdBlock = true,
  localPlacement,
  webView = false,
}) => {
  // ======================= HOOKS

  const [visible, setVisible] = useState(false);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });

  // ======================= STATE
  const [data, setData] = useState<any>();

  // ======================= API
  const fetchData = async (inPlacement: AdsBlockType) => {
    try {
      const res = await getAds(inPlacement);
      setData(res);
    } catch (err) {
      console.log('err', err);
    }
  };

  // ======================= EVENTS
  const onCloseDialog = () => {
    setVisible(false);
  };

  // ======================= EFFECTS
  useEffect(() => {
    const newsPopupDate = localStorage.getItem('news_popup_date');
    const currentDate = new Date().toDateString();
    const hasAdsBlockPopupToday = newsPopupDate === currentDate;
    if (!hasAdsBlockPopupToday && !webView) {
      fetchData(localPlacement);
    }
  }, [localPlacement, webView]);

  useEffect(() => {
    if (data) {
      setVisible(true);
      const currentDate = new Date().toDateString();
      localStorage.setItem('news_popup_date', currentDate);
    }
  }, [data]);

  if (isEmpty(data)) return null;
  return (
    <Dialog
      visible={visible}
      PaperProps={{
        sx: {
          p: 0,
          borderRadius: 0,
          border: 'none',
          overflow: 'visible',
          backgroundColor: 'transparent',
          ...(isTabletOrMobile && {
            maxWidth: { md: '75%' },
          }),
        },
      }}
      contentProps={{
        sx: {
          p: 0,
          m: 0,
          bgcolor: 'transparent',
          maxHeight: '768px',
          overflow: 'visible',
        },
      }}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            backdropFilter: 'blur(0.5px)',
          },
        },
      }}
      maxWidth="lg"
      onClose={onCloseDialog}
    >
      <Stack>
        <Link
          href={data?.ads_block_placement_link}
          target={data?.ads_block_placement_link_type}
          style={{
            lineHeight: 0,
            width: '100%',
            padding: 0,
            margin: 0,
            border: 'none',
            cursor: 'pointer',
            ...containerStyle,
          }}
        >
          <StyledImage
            src={
              isTabletOrMobile
                ? data?.ads_block_placement_media_mobile_url
                : data?.ads_block_placement_media_desktop_url
            }
            width={1}
            height={1}
            imageStyle={imageStyle}
            loading="lazy"
            priority={false}
            alt={data?.ads_block_placement_title}
            checkAdblock={checkAdBlock}
          />
        </Link>
        <Stack
          sx={{
            position: 'absolute',
            top: { xs: -28, sm: -35, md: -50 },
            right: { xs: 0, md: -50 },
          }}
        >
          <IconButton
            sx={{
              backgroundColor: { xs: 'transparent', md: '#FFFFFF4C' },
              '&:hover': {
                backgroundColor: {
                  xs: 'transparent !important',
                  md: '#FFFFFF4C !important',
                },
              },
              p: { xs: 0, md: 2.5 },
            }}
            onClick={onCloseDialog}
          >
            <CloseIcon
              className="arrowColor"
              sx={{
                color: '#FFF',
                fontSize: { xs: '1.1rem', sm: '1.4rem', md: '1.5rem' },
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AdsPopup;
