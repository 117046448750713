import {
  forwardRef,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import MuiDialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import isNil from 'lodash/isNil';

import { DialogActionConfigType, DialogProps, DialogRefProps } from './props';
import { getDialogActions } from './settings';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * ===========================
 * MAIN
 * ===========================
 */
const DialogView: React.ForwardRefRenderFunction<
  DialogRefProps,
  DialogProps
> = (props, ref) => {
  const {
    type,
    primaryButtonVariant = 'text',
    secondaryButtonVariant = 'text',
    closeButtonProps,
    showDivider = false,
    title,
    message,
    visible = false,
    enableCloseButton = false,
    actions = [],
    titleProps,
    contentProps,
    messageProps,
    actionProps,
    children,
    primaryText,
    secondaryText,
    onClose,
    onClickAction,
    onClickPrimaryAction,
    onClickSecondaryAction,
    ...restProps
  } = props;

  // =============== VARIABLES
  const dialogActions = getDialogActions(type, actions, {
    primaryText,
    secondaryText,
  });

  // =============== STATE
  const [visibleState, setVisibleState] = useState(visible);

  // =============== EFFECTS
  useEffect(() => setVisibleState(visible), [visible]);

  // =============== HOOKS
  useImperativeHandle(ref, () => {
    return {
      toggle: (inState) => {
        if (!isNil(inState)) {
          setVisibleState(inState);
          return;
        }
        setVisibleState(!visibleState);
      },
      open: () => setVisibleState(true),
      close: () => setVisibleState(false),
    };
  });

  // =============== EVENTS
  const onHandleClose = () => {
    setVisibleState(false);
  };

  const onHandleClick = (action: DialogActionConfigType) => {
    return (e: MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();

      switch (action.type) {
        case 'primary':
          onClickPrimaryAction?.();
          onClickAction?.('primary');
          break;
        case 'secondary':
          onClickSecondaryAction?.();
          onClickAction?.('secondary');
          break;
        default:
          onClickAction?.(action.key ?? action.type);
          break;
      }
      onHandleClose();
    };
  };

  // =============== VIEWS

  return (
    <MuiDialog
      {...restProps}
      fullWidth
      sx={{
        zIndex: 1301,
      }}
      open={visibleState}
      onClose={(event, reason) => {
        setVisibleState(false);
        onClose?.(event, reason);
      }}
      TransitionComponent={Transition}
    >
      {title && (
        <DialogTitle {...titleProps}>
          {title}
          {enableCloseButton && (
            <IconButton
              aria-label="close"
              onClick={() => {
                onClickAction?.('close');
                onHandleClose();
              }}
              {...closeButtonProps}
              sx={{
                position: 'absolute',
                top: 15,
                right: 5,
                color: (theme) => theme.palette.text.primary,
                ...closeButtonProps?.sx,
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      {showDivider && <Divider />}
      <DialogContent sx={{ px: 0, pt: 0 }} {...contentProps}>
        {message && (
          <DialogContentText {...messageProps}>{message}</DialogContentText>
        )}
        {children}
      </DialogContent>
      {dialogActions.length > 0 && (
        <DialogActions {...actionProps}>
          {dialogActions.map((action, i) => {
            const type = action.type;
            const variant =
              type === 'primary'
                ? primaryButtonVariant
                : secondaryButtonVariant;
            return (
              <Button
                key={`${action.label}-${i}`}
                variant={type === 'custom' ? 'text' : variant}
                {...action.props}
                onClick={onHandleClick(action)}
              >
                {action.label}
              </Button>
            );
          })}
        </DialogActions>
      )}
    </MuiDialog>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export * from './props';
export const Dialog = forwardRef(DialogView);
