/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import BrandGroup from '@apps/components/BrandGroup';
import HomeBanner from '@apps/components/HomeBanner';
import ImageContent from '@apps/components/ImageContent';
import { getSocialVideos } from '@apps/config/API/social-video.config';
import AdsPopup from '@apps/containers/AdsPopup';
import FeatureNews from '@apps/containers/FeatureNewsSection';
import ModelSection from '@apps/containers/ModelSection';
import MostReadSection from '@apps/containers/MostReadSection';
import NewEvCarSection from '@apps/containers/NewEvCarSection';
import NewsCategorySection from '@apps/containers/NewsCategorySection';
import { NewsSection } from '@apps/containers/NewsSection';
import SocialVideoSection from '@apps/containers/SocialVideoSection';
import { getDateSlug } from '@apps/utils/get-date-slug';
import placeholderImage from '@stack/assets/images/empty_placeholder.jpg';
import { BoxHeader } from '@stack/ui/components';
import { toLower } from 'lodash';
import useTranslation from 'next-translate/useTranslation';

import { LatestNewsWrapper } from './styles';
// import BannerMobile from '@stack/assets/images/banner-mobile.jpg'

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const TestScreen: React.FC = (props: any) => {
  const { settings, data, webView } = props;
  const { t } = useTranslation('common');
  const [ct, setCT] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [videos, setVideos] = useState<any>(null);

  // ======================= API
  const fetchSocialVideoData = async () => {
    try {
      setLoading(true);
      const videosData = await getSocialVideos({
        channelId: ct ?? '',
        pageSize: 12,
        page: 1,
      });

      setLoading(false);
      setVideos({
        tiktok: [...videosData.tiktok],
        youtube: [...videosData.youtube],
      });
      return videosData;
    } catch (err) {
      console.log('err', err);
    }
  };

  useEffect(() => {
    setVideos(null);
    fetchSocialVideoData();
  }, [ct]);

  const handleCTChange = async (inCT: any) => {
    if (inCT === ct) return;
    setVideos(null);
    setCT(inCT);
  };

  const popularModels = settings?.car_model_popular ?? [];
  const carBrand = settings?.car_brand_featured ?? [];
  const fuel = settings?.fuel_category ?? [];
  const jpjNumber = data?.jpjNumber;
  const newCars = data?.newCars;
  const categoryPost = data?.postCategoryAll ?? [];
  const settingBanner = data?.settingBanner || [];

  const evNews = categoryPost?.find((cp: any) => {
    return cp.post_category_slug === 'ev';
  });
  const reviewNews = categoryPost?.find((cp: any) => {
    return cp.post_category_slug === 'review';
  });
  const techNews = categoryPost?.find((cp: any) => {
    return cp.post_category_slug === 'tech';
  });
  const tipsNews = categoryPost?.find((cp: any) => {
    return cp.post_category_slug === 'tips';
  });
  const sideNews = data?.sideNews ?? [];

  const topLatestNews = data?.topLatestNews ?? [];
  const mainNews = data?.mainNews;
  const remainLatestNews = data?.remainLatestNews ?? [];

  const mostReadNews = data?.postMostRead ?? [];

  const getPostHref = (post: any) => {
    if (!post?.post_title) return;
    const dateSlug = getDateSlug(post.post_created);
    return webView
      ? `/webview/${dateSlug}/${toLower(post?.post_slug)}`
      : `/${dateSlug}/${toLower(post?.post_slug)}`;
  };

  // =============== VIEWS
  return (
    <>
      <div>
        <AdsPopup localPlacement="news_home_popup" webView={webView} />
        {!!settingBanner?.length && (
          <HomeBanner data={settingBanner} webView={webView} />
        )}
        <div
          className="grid custom-grid-3-col gap-30"
          style={{ paddingBlock: '1rem' }}
        >
          <div className="grid-item">
            <ImageContent
              imageProps={{
                src: mainNews?.post_image || placeholderImage.src,
                width: 600,
                height: 315,
                alt: mainNews?.post_title || '',
              }}
              imagePosition="top"
              tabletImagePosition="top"
              title={mainNews?.post_title}
              titleSlug={mainNews?.post_slug}
              footerText={mainNews?.post_created}
              author={mainNews?.post_author}
              authorAvatar={mainNews?.post_author_profile_image}
              authorEmail={mainNews?.post_author_username}
              description={mainNews?.post_short_description}
              titleClass="text-5xl"
              badgeLabel={mainNews?.category_name}
              descriptionLine={3}
              footerTextJustify="flex-start"
              href={getPostHref(mainNews)}
            />
          </div>
          <div className="grid-item">
            <BoxHeader title={t('latestNews')}>
              <LatestNewsWrapper className="list ">
                {topLatestNews &&
                  topLatestNews.map((rd: any) => {
                    return (
                      <div
                        className="grid-item grid-border"
                        key={rd?.post_slug}
                      >
                        <ImageContent
                          title={rd.post_title}
                          titleSlug={rd?.post_slug}
                          titleLine={2}
                          titleClass="text-base"
                          footerText={rd?.post_created}
                          author={rd?.post_author}
                          authorAvatar={rd?.post_author_profile_image}
                          authorEmail={rd?.post_author_username}
                          href={getPostHref(rd)}
                          imagePosition="left"
                          imageProps={{
                            src: rd?.post_image || placeholderImage.src,
                            width: 600,
                            height: 315,
                            alt: rd?.post_title,
                          }}
                          maxImageWidth="138.56px"
                          mobileImagePostion="left"
                          simpleMobileView={true}
                          tabletImagePosition={'left'}
                        />
                      </div>
                    );
                  })}
              </LatestNewsWrapper>
              {/* <ShowMoreButton href="/" /> */}
            </BoxHeader>
          </div>
          <div className="grid-item">
            {carBrand && <BrandGroup brands={carBrand} webView={webView} />}
          </div>
        </div>

        {mostReadNews && mostReadNews.length > 0 && (
          <MostReadSection news={mostReadNews} webView={webView} />
        )}
        {evNews && <NewsSection news={evNews} webView={webView} />}
        <NewEvCarSection cars={newCars} webView={webView} />
        <ModelSection models={popularModels} webView={webView} />
        {(reviewNews?.data?.length > 0 ||
          techNews?.data?.length > 0 ||
          tipsNews?.data?.length > 0) && (
          <NewsCategorySection
            reviewNews={reviewNews}
            techNews={techNews}
            tipsNews={tipsNews}
            webView={webView}
          />
        )}
        {!webView && <ModelSection models={popularModels} webView={webView} />}
        {!webView && (
          <SocialVideoSection
            data={{ channels: data.channels, videos: videos }}
            setChannelType={handleCTChange}
            loading={loading}
          />
        )}
        <ModelSection models={popularModels} webView={webView} />
        <FeatureNews
          latestNews={remainLatestNews}
          fuels={fuel}
          jpjNumber={jpjNumber}
          sideNews={sideNews}
          webView={webView}
        />
      </div>
    </>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default TestScreen;
